import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SwingImg from '../../../assets/images/about-us-gate.jpg'

class SwingGates extends React.Component {
	render() {
		const siteTitle = 'Swing Gates - Arlington, TX and Dallas-Fort Worth, TX'
		const siteDescription =
			'Wanting a Swing Gate Installed in the Arlington, TX or Dallas-Fort Worth, TX Area? Call  To Learn More About Our Gate Services'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Swing Gates in Arlington, TX</h1>
						<hr />
					</div>
				</div>
				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<p>
								If you want a touch of elegance or have limited space, a swing
								gate can provide an excellent option. Common among homeowners,
								these gates provide both security and an elevated level of
								style.{' '}
								<strong>
									<em>
										If you need swing gate{' '}
										<Link to="/gates/services/gate-installation/">
											installation
										</Link>{' '}
										and repair in Dallas and Fort Worth, TX, call J & J Gates
										Service and Design at!
									</em>
								</strong>
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<h4>Types of Swing Gates</h4>
							<p>
								Swing gates add compact style to your home or business because
								they swing open, as opposed to sliding gates, which require much
								more space. This is why sliding gates are more common on large
								estates or in commercial settings. For people living in homes
								closer together, swing gates often work better because they do
								not require as much space along your fence line.
							</p>
							<p>
								Dual swing gates, which have two gates that swing open from the
								middle, give an air of elegance. These gates work well for
								homeowners, who may want to make their home the most admired in
								their neighborhood. Your neighbors will be astonished and
								impressed when they see your new swing gates! And just like
								every other gate, they give your property another layer of
								security.
							</p>
						</div>
						<div className="col-md-4 mt-5">
							<LazyLoadImage
								className="img-fluid mt-5"
								src={SwingImg}
								alt="Swing Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<p>
								There are also single swing gates, which open from the side and
								only feature one gate. Also used in residential areas, these
								types of gates tend to be more popular among apartments, gated
								neighborhoods, or similar areas. Available in a wide selection
								of styles, materials, and colors, a swing gate can provide style
								and security. Often less flashy than a double swing gate, a
								single swing gate can still add value to your home without
								competing for attention.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Professional Installation and Repair!</h4>
							<p>
								Do you have an aging swing gate that needs{' '}
								<Link to="/gates/services/gate-repair/">repair</Link>? Whether
								we installed it or someone else did, J & J Gates Service and
								Design is happy to repair or replace swing gates in Dallas and
								Fort Worth, TX. Whether you need swing gate installation or
								repair in the DFW area, call us at for assistance!
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default SwingGates

export const SwingGatesPageQuery = graphql`
	query SwingGatesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
